import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import {
  Checkbox,
  SignatureInput,
  TextInput
} from '@thedealersconcierge/components';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import config from '~/config';
import i18n from '~/i18n';

export type CreditApplicationConsentFormValues = {
  hasAcceptedAcknowledgements: boolean;
  hasAcceptedElectronicDisclosure: boolean;
  hasAcceptedContactConsent: boolean;
  fullName: string;
  signature: string;
};

export const ValidCreditApplicationConsentSchema = (
  t: TFunction,
  type: 'WEB' | 'IN_APP'
) =>
  z.object({
    hasAcceptedAcknowledgements: z.literal(true, {
      message: t('This field has to be accepted')
    }),
    hasAcceptedElectronicDisclosure: z.literal(true, {
      message: t('This field has to be accepted')
    }),
    hasAcceptedContactConsent:
      type === 'WEB'
        ? z.literal(true, {
            message: t('This field has to be accepted')
          })
        : z.boolean(),
    fullName: z.string().min(1, t('This field is required')),
    signature: z.string().min(1, t('This field is required'))
  });

const CreditApplicationConsentForm: FC<{
  dealershipSlug: string;
  dealershipName: string;
  form: ReactFormApi<CreditApplicationConsentFormValues, ZodValidator>;
  isSubmitting: boolean;
  className?: string;
  type: 'WEB' | 'IN_APP';
  dataTestId?: string;
}> = ({
  dealershipSlug,
  dealershipName,
  form,
  isSubmitting,
  type,
  className,
  dataTestId
}) => {
  const { t } = useTranslation();

  return (
    <form
      className={twMerge(
        'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
        'space-y-spacing-06 text-secondary',
        className
      )}
      data-test-id={dataTestId}
    >
      <div className="space-y-spacing-04">
        <div
          className={classNames('space-y-spacing-02', {
            hidden: type === 'IN_APP'
          })}
        >
          <h4 className="heading-04">{t('Our Commitment to Privacy')}</h4>

          <p className="body-01 ml-spacing-06">
            <span>
              {t(
                'All information stored in our database is secure and is strictly confidential. Your personal and credit information will only be used to fulfill your request and in accordance with our '
              )}
            </span>

            <a
              className="text-blue-500 hover:underline"
              href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
              target="_blank"
            >
              {t('Privacy Policy')}
            </a>

            <span>.</span>
          </p>
        </div>

        <div className="space-y-spacing-02">
          <h4 className="heading-04">
            {t('Acknowledgements, Consents and Disclosures')}
          </h4>

          <form.Field
            name="hasAcceptedAcknowledgements"
            validators={{
              onChange: z.literal(true, {
                message: t('This field has to be accepted')
              })
            }}
          >
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                  data-test-id={`${dataTestId}-has-accepted-acknowledgements`}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    <Trans t={t}>
                      By clicking the "Submit" button and providing an
                      electronic signature, I acknowledge that this is a Credit
                      Application. I certify that all the information I have
                      provided is accurate and truthful. I further authorize{' '}
                      {dealershipName} (hereafter "The Dealership") to obtain my
                      personal credit profile and related information from
                      Experian, TransUnion, and Equifax, in accordance with the
                      Fair Credit Reporting Act. This authorization allows The
                      Dealership to conduct a credit pre-qualification and
                      submit my application to multiple lending institutions on
                      my behalf. I also consent to The Dealership sharing the
                      information from this application with affiliated third
                      parties for potential financing options. I understand that
                      this submission will affect my credit score.
                      <br />
                      <br />
                      Additionally, I agree to the{' '}
                      <a
                        target="_blank"
                        className="text-blue-500 hover:underline"
                        href={t('privacy-notice', {
                          backendUrl: config.rawBackendUrl,
                          dealershipSlug: dealershipSlug,
                          lang: i18n.language,
                          ns: 'files',
                          defaultValue:
                            '{{backendUrl}}/document/{{dealershipSlug}}/{{lang}}/privacy-notice.pdf'
                        })}
                      >
                        Privacy Notice
                      </a>{' '}
                      and{' '}
                      <a
                        target="_blank"
                        className="text-blue-500 hover:underline"
                        href="https://files.mytdc.net/prequal-terms-and-conditions.pdf"
                      >
                        Disclosures
                      </a>
                      , and I acknowledge that I may be contacted by The
                      Dealership. I also understand that prequalification does
                      not guarantee approval and may be subject to specific
                      criteria.
                    </Trans>
                  </p>
                </div>
              );
            }}
          </form.Field>
        </div>

        <div className="space-y-spacing-02">
          <h4 className="heading-04">
            {t('Electronic Disclosure Consent and Acknowledgment')}
          </h4>

          <form.Field
            name="hasAcceptedElectronicDisclosure"
            validators={{
              onChange: z.literal(true, {
                message: t('This field has to be accepted')
              })
            }}
          >
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                  data-test-id={`${dataTestId}-has-accepted-electronic-disclosure`}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    <Trans t={t}>
                      I hereby consent and acknowledge that I will receive all
                      required disclosures electronically via the TDC platform.
                      I understand that access to these disclosures will be
                      available through the TDC Buyer Portal at{' '}
                      <a
                        target="_blank"
                        className="text-blue-500 hover:underline"
                        href="https://app.mytdc.net/signin"
                      >
                        https://app.mytdc.net/signin
                      </a>{' '}
                      By providing my consent, I agree to review and retain the
                      provided electronic disclosures as necessary.
                    </Trans>
                  </p>
                </div>
              );
            }}
          </form.Field>
        </div>

        <div
          className={classNames('space-y-spacing-02', {
            hidden: type === 'IN_APP'
          })}
        >
          <h4 className="heading-04">{t('Contact Consent')}</h4>

          <form.Field
            name="hasAcceptedContactConsent"
            validators={{
              onChange:
                type === 'WEB'
                  ? z.literal(true, {
                      message: t('This field has to be accepted')
                    })
                  : z.boolean()
            }}
            data-test-id={`${dataTestId}-has-accepted-contact-consent`}
          >
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    {t(
                      "Having provided my phone number, I consent to receive texts and emails from The Dealership. I can opt out anytime. My consent isn't required for purchase, and I accept any messaging and any data charges that may apply by my service provider."
                    )}
                  </p>
                </div>
              );
            }}
          </form.Field>
        </div>
      </div>

      <div className="space-y-spacing-04">
        <h2 className="heading-02">
          {t('Please enter your full name before signing:')}
        </h2>

        <form.Field
          name="fullName"
          validators={{
            onBlur: z.string().min(1, t('This field is required'))
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                label={t('Full Name')}
                placeholder={t('Enter your Full Name')}
                required={true}
                disabled={isSubmitting}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                dataTestId={`${dataTestId}-full-name`}
              />
            );
          }}
        </form.Field>
      </div>

      <form.Field
        name="signature"
        validators={{
          onChange: z.string().min(1, t('This field is required'))
        }}
      >
        {(field) => {
          return (
            <div
              className={classNames('flex flex-col w-full space-y-spacing-01', {
                'opacity-40': isSubmitting
              })}
            >
              <SignatureInput
                value={field.state.value}
                onSubmit={field.handleChange}
                required
                disabled={isSubmitting}
                dataTestIdOpenModal={`${dataTestId}-signature-open-modal`}
                dataTestIdCanvas={`${dataTestId}-signature-canvas`}
                dataTestIdSubmit={`${dataTestId}-signature-submit`}
              />
            </div>
          );
        }}
      </form.Field>
    </form>
  );
};

export default CreditApplicationConsentForm;

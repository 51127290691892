import { Dialog } from '@headlessui/react';
import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../../button';

// This is a custom graphic specifically for this component
const SignatureIconGraphic: FC<{ t: TFunction; className?: string }> = ({
  t,
  className
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 40 40"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>{t('Signature icon graphic')}</title>

      <g
        transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M281 369 l-24 -9 23 -25 c28 -30 40 -32 40 -6 0 11 5 23 10 26 13 8
3 25 -13 24 -7 0 -23 -5 -36 -10z"
        />

        <path
          d="M183 264 c-78 -88 -80 -92 -54 -118 l22 -21 68 75 c38 41 72 72 77
67 4 -4 -2 -17 -14 -30 -39 -41 -23 -42 18 -2 43 42 51 60 21 50 -10 -3 -24 1
-31 10 -34 41 -46 38 -107 -31z"
        />

        <path
          d="M83 123 c-3 -10 -8 -21 -10 -25 -7 -13 22 -9 40 5 16 11 16 13 -3 25
-17 11 -21 10 -27 -5z"
        />

        <path
          d="M211 59 c-21 -29 -23 -29 -36 -12 -12 16 -17 16 -46 6 -22 -9 -36 -9
-45 -2 -8 6 -14 7 -18 0 -12 -18 30 -32 58 -19 21 9 29 8 44 -6 18 -15 21 -15
47 6 27 21 28 21 42 2 14 -19 15 -18 34 5 21 26 18 41 -5 22 -12 -10 -17 -8
-26 8 -14 28 -22 26 -49 -10z"
        />
      </g>
    </svg>
  );
};

/**
 * This component renders a large input field with a button and graphic on it
 * When clicking on it, a modal with a pad will open.
 * Upon submitting the signature, a submit callback is called.
 *
 * @param required: We do not provide a default value to make sure the decision is made explicitly
 * @param disabled: We do not provide a default value to make sure the decision is made explicitly
 * @returns
 */
const SignatureInput: FC<{
  value: null | string;
  required: boolean;
  disabled: boolean;
  onSubmit: (signature: string) => Promise<void> | void;
  errorMessage?: string;
  dataTestIdOpenModal?: string;
  dataTestIdCanvas?: string;
  dataTestIdSubmit?: string;
}> = ({
  value,
  required,
  disabled,
  onSubmit,
  errorMessage,
  dataTestIdOpenModal,
  dataTestIdCanvas,
  dataTestIdSubmit
}) => {
  const { t } = useTranslation();
  const [signautreRef, setSignautreRef] = useState<SignatureCanvas | null>(
    null
  );
  const [showPad, setShowPad] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const handleShowPad = () => {
    if (!disabled) {
      setShowPad(true);
    }
  };
  const handleClosePad = () => {
    setShowPad(false);
  };
  const handleEnd = () => {
    setIsEmpty(!!signautreRef?.isEmpty());
  };
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    signautreRef?.off();

    const signatureDataUrl = signautreRef?.toDataURL('image/png');

    if (!signatureDataUrl) {
      throw new Error('No signature found');
    }

    await onSubmit(signatureDataUrl);
    handleClosePad();
    setIsSubmitting(false);
    signautreRef?.on();
  }, [signautreRef, onSubmit]);

  return (
    <Fragment>
      <Dialog open={showPad} onClose={handleClosePad}>
        <div
          className="fixed inset-0 z-30 flex justify-center items-center px-spacing-02 tablet:px-spacing-04 bg-black bg-opacity-40"
          onClick={() => {
            if (!isSubmitting) {
              handleClosePad();
            }
          }}
          data-test-id="signature-modal"
        >
          <div
            className="z-20 flex flex-col w-full bg-primary p-spacing-04 tablet:p-spacing-05 space-y-spacing-04 tablet:space-y-spacing-05 rounded-radius-04 justify-center shadow-shadow-05 max-w-screen-sm"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="flex flex-row justify-between items-start">
              <h2 className="heading-emphasized-02">{t('Signature')}</h2>

              <Button
                icon={<Close />}
                onClick={handleClosePad}
                variant="GHOST"
                size="XSMALL"
                className="icon-secondary"
              />
            </div>

            <SignatureCanvas
              ref={(ref) => {
                setSignautreRef(ref);

                if (dataTestIdCanvas) {
                  ref
                    ?.getCanvas()
                    .setAttribute('data-test-id', dataTestIdCanvas);
                }
              }}
              // We don't set background as it needs to be transparent
              penColor="black"
              minWidth={1}
              canvasProps={{
                className: 'sigCanvas border rounded-2xl aspect-[3/2]'
              }}
              onEnd={handleEnd}
            />

            <div className="flex justify-between">
              <Button
                label={t('Cancel')}
                variant="GHOST"
                onClick={handleClosePad}
                disabled={isSubmitting}
                size="LARGE"
              />

              <Button
                label={t('Submit')}
                dataTestId={dataTestIdSubmit}
                onClick={() => {
                  void handleSubmit();
                }}
                isLoading={isSubmitting}
                disabled={isEmpty}
                size="LARGE"
              />
            </div>
          </div>
        </div>
      </Dialog>

      <div className="flex flex-col space-y-spacing-02">
        <div className="flex flex-row items-center">
          <p className="body-02 text-secondary">{t('Signature')}</p>

          {required && <p className="label-02 text-danger">*</p>}
        </div>

        <div
          className={classNames(
            'bg-secondary flex flex-row justify-center py-spacing-04 rounded-radius-02',
            {
              'cursor-pointer': !value && !disabled
            }
          )}
          onClick={handleShowPad}
          data-test-id={dataTestIdOpenModal}
        >
          {!value && (
            <div className="flex flex-row space-x-spacing-03 items-center">
              <div className="relative size-8 tablet:size-10 icon-interactive">
                <SignatureIconGraphic t={t} />
              </div>

              <Button
                label={t('Tap here to sign')} // This label is the same throughout the app. Therefore, we are setting it here and not via a prop
                size="XSMALL"
                className="tablet:w-48 h-fit"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleShowPad();
                }}
              />
            </div>
          )}

          {value && (
            <div className="relative flex justify-center w-full h-8 tablet:h-10">
              <img src={value} className="h-full object-contain" />
            </div>
          )}
        </div>

        <p
          className={classNames(
            'label-02 font-normal text-start min-h-spacing-04',
            {
              'text-danger': Boolean(errorMessage)
            }
          )}
        >
          {errorMessage}
        </p>
      </div>
    </Fragment>
  );
};

export default SignatureInput;
